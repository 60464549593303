import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { FetchState } from '@/modules/http/types';
import { SellerStatusResponse } from '@/modules/sellerOnboarding/types';
import { fetchSellerStatusApiRequest } from '@/modules/sellerOnboarding/api';
import { GetState } from '@/modules/redux/stores/storeClient';

export interface SellerOnboardingState {
  sellerStatus: {
    fetchState: FetchState;
    data: SellerStatusResponse;
    isInitialData: boolean;
  };
}

export const initialState: SellerOnboardingState = {
  sellerStatus: {
    fetchState: FetchState.NotStarted,
    isInitialData: true,
    data: {
      stripe: {
        isConnected: false,
        isEligible: false,
      },
      payPal: {
        isEligible: false,
        isConnected: false,
      },
      user: {
        country: '',
      },
      canSell: false,
    },
  },
};

export const sellerOnboardingSlice = createSlice({
  name: 'sellerOnboarding',
  initialState,
  reducers: {
    fetchSellerStatusRequest: (state) => {
      state.sellerStatus.fetchState = FetchState.Fetching;
    },
    fetchSellerStatusSuccess: (
      state,
      action: PayloadAction<{ data: SellerStatusResponse }>
    ) => {
      state.sellerStatus.data = action.payload.data;
      state.sellerStatus.isInitialData = false;
      state.sellerStatus.fetchState = FetchState.Success;
    },
    fetchSellerStatusError: (state) => {
      state.sellerStatus.fetchState = FetchState.Error;
    },
    updateSellerStatusBillingAddress: (
      state,
      action: PayloadAction<
        Pick<
          SellerStatusResponse,
          'billingFirstName' | 'billingLastName' | 'billingAddress'
        >
      >
    ) => {
      state.sellerStatus.isInitialData = false;
      state.sellerStatus.data = {
        ...state.sellerStatus.data,
        ...action.payload,
      };
    },
    updateSellerStatusPayPalConnected: (state) => {
      state.sellerStatus.isInitialData = false;
      state.sellerStatus.data.payPal.isConnected = true;
    },
    updateSellerStatusStripeConnected: (state) => {
      state.sellerStatus.isInitialData = false;
      state.sellerStatus.data.stripe.isConnected = true;
    },
    updateSellerStatusDepopPaymentsEligibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.sellerStatus.isInitialData = false;
      state.sellerStatus.data.stripe.isEligible = action.payload;
    },
    updateSellerStatusCanSell: (state) => {
      state.sellerStatus.isInitialData = false;
      state.sellerStatus.data.canSell = true;
    },
  },
});

export const {
  fetchSellerStatusRequest,
  fetchSellerStatusSuccess,
  fetchSellerStatusError,
  updateSellerStatusBillingAddress,
  updateSellerStatusPayPalConnected,
  updateSellerStatusStripeConnected,
  updateSellerStatusDepopPaymentsEligibility,
  updateSellerStatusCanSell,
} = sellerOnboardingSlice.actions;

export function fetchSellerStatus() {
  return async function (
    dispatch: Dispatch
  ): Promise<SellerStatusResponse | void> {
    dispatch(fetchSellerStatusRequest());

    try {
      const { data } = await fetchSellerStatusApiRequest();
      dispatch(fetchSellerStatusSuccess({ data }));
      return data;
    } catch {
      dispatch(fetchSellerStatusError());
    }
  };
}

export function getOrFetchSellerStatus(revalidateData?: boolean) {
  return function (dispatch: Dispatch, getState: GetState) {
    const { data, isInitialData } = getState().sellerOnboarding.sellerStatus;

    if (!isInitialData && !revalidateData) {
      return Promise.resolve(data);
    }

    return fetchSellerStatus()(dispatch);
  };
}

export default sellerOnboardingSlice.reducer;
