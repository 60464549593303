import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentItemId } from '@/modules/sellerhub/navigation/types';

interface PaymentModalState {
  isOpen: boolean;
  type: PaymentItemId;
}

export interface SellerHub {
  paymentModal: PaymentModalState;
}

const initialState: SellerHub = {
  paymentModal: {
    isOpen: false,
    type: PaymentItemId.BankAccount,
  },
};

export const sellerHubSlice = createSlice({
  name: 'sellerHub',
  initialState,
  reducers: {
    setPaymentModal: (state, action: PayloadAction<PaymentModalState>) => {
      state.paymentModal = action.payload;
    },
  },
});

export const { setPaymentModal } = sellerHubSlice.actions;

export default sellerHubSlice.reducer;
