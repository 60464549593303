import { useSelector } from 'react-redux';

import { locations, CurrencyCode } from './constants';

import { RootState } from '@/modules/redux/stores/storeClient';

// @TODO 17/10/23 - Reinstate locale routing stuff in here once we get to i18n
export function useCurrentLocation() {
  const location =
    useSelector((state: RootState) => state.location)?.value || 'us';

  function getCurrencyCodeByLocation() {
    const userLocation = locations.find((loc) => loc.value === location);
    return userLocation?.defaultCurrency || CurrencyCode.USD;
  }

  return { location, getCurrencyCodeByLocation };
}
