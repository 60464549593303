export type AxiosCompatibleResponse<T = unknown> = {
  data: T;
  status: number;
  headers: Headers;
  url?: string;
};

export type RequestConfig = {
  url?: string;
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  data?: unknown;
  params?: Record<string, unknown>;
  withDeviceId?: boolean;
  withAuth?: boolean;
  cache?: 'no-store' | 'force-cache';
  revalidate?: false | number;
  keepalive?: boolean;
  httpsAgent?: unknown;
};

export type AxiosCompatibleError<T = unknown> = {
  config: RequestConfig;
  code?: number;
  response: AxiosCompatibleResponse<T>;
};

export interface RawRowAndFieldError {
  row_number: number;
  field: string;
  message: string;
}

export type ParsedError =
  | {
      message?: string;
      error?: string;
      error_message?: string;
      file_level_error: string;
      row_level_errors: RawRowAndFieldError[];
    }
  | string
  | null;

export enum FetchState {
  NotStarted = 'notStarted',
  Fetching = 'fetching',
  Success = 'success',
  Error = 'error',
}
