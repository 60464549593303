export enum NavItemId {
  Overview = 'overview',
  Sold = 'sold',
  Selling = 'selling',
  SellingActive = 'sellingActive',
  SellingInactive = 'sellingInactive',
  Offers = 'offers',
  BoostedListings = 'boostedListings',
  Drafts = 'drafts',
  Stats = 'stats',
  ListItemButton = 'listItemButton',
  BulkListingButton = 'bulkListingButton',
  Payments = 'payments',
  Vacation = 'vacation',
  ShopSettings = 'shopSettings',
  Shipping = 'shipping',
  ReadyToPost = 'readyToPost',
  Incomplete = 'incomplete',
}

export enum PaymentItemId {
  Balance = 'balance',
  BankAccount = 'bankAccount',
  Verification = 'verification',
}

export type NavId = NavItemId | PaymentItemId;
