import {
  BillingAgreementRequestParams,
  BillingAgreementTokenResponse,
  CheckDepopPaymentsEligibilityRequest,
  CheckDepopPaymentsEligibilityResponse,
  CreateCustomStripeUserRequest,
  CreateCustomStripeUserResponse,
  SellerStatusResponse,
} from './types';

import {
  BILLING_AGREEMENT_SUBMISSION_ENDPOINT,
  BILLING_AGREEMENT_TOKEN_ENDPOINT,
  CREATE_STRIPE_USER_ENDPOINT,
  DEPOP_PAYMENTS_ELIGIBILITY_ENDPOINT,
  SELLER_STATUS_ENDPOINT,
} from '@/constants/endpoints';
import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';

export function fetchBillingAgreementToken(): Promise<
  AxiosCompatibleResponse<BillingAgreementTokenResponse>
> {
  return http.post(
    BILLING_AGREEMENT_TOKEN_ENDPOINT,
    {},
    {
      withAuth: true,
    }
  );
}

export function updateBillingAgreement(params: BillingAgreementRequestParams) {
  return http.put(BILLING_AGREEMENT_SUBMISSION_ENDPOINT, params, {
    withAuth: true,
  });
}

export function createCustomStripeUser(
  payload: CreateCustomStripeUserRequest
): Promise<AxiosCompatibleResponse<CreateCustomStripeUserResponse>> {
  return http.post(CREATE_STRIPE_USER_ENDPOINT, payload, {
    withAuth: true,
  });
}

export function fetchSellerStatusApiRequest(): Promise<
  AxiosCompatibleResponse<SellerStatusResponse>
> {
  return http.get(SELLER_STATUS_ENDPOINT, { withAuth: true });
}

export function checkDepopPaymentsEligibility(
  payload: CheckDepopPaymentsEligibilityRequest
): Promise<AxiosCompatibleResponse<CheckDepopPaymentsEligibilityResponse>> {
  return http.post(DEPOP_PAYMENTS_ELIGIBILITY_ENDPOINT, payload, {
    withAuth: true,
  });
}
